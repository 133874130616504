// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysDeliveryDate(date)
{
    if (!date)
        return "";

    if (typeof date === 'string' || date instanceof String)
        date = new Date(date);

    const m = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
    const nowDate = new Date();
    nowDate.setHours(0, 0, 0, 0);

    const tomorrowDate = new Date();
    tomorrowDate.setHours(0, 0, 0, 0);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    let date_prefix = "";
    if (date.valueOf() === nowDate.valueOf())
        date_prefix = "Сегодня, ";
    else if (date.valueOf() === tomorrowDate.valueOf())
        date_prefix = "Завтра, ";

    return (date_prefix + date.getDate() + " " + m[date.getMonth()]);
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysDeliveryTime(time_begin, time_end)
{
    if (!time_begin || !time_end)
        return "";

    return "с " + parseInt(time_begin).toString() + ':00 до ' + parseInt(time_end).toString() + ":00";
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysDateShort(date)
{
    if (!date)
        return "";
    
    if (typeof date === 'string' || date instanceof String)
        date = new Date(date);

    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }


    return dd + '.' + mm;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysDateDay(date, flag_short)
{
    if (!date)
        return "";

    const d = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
    const s = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    if (typeof date === 'string' || date instanceof String)
        date = new Date(date);

    return flag_short ? s[date.getDay()] : d[date.getDay()];
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysDate(date, flag_time)
{
    if (!date)
        return "";

    if (typeof date === 'string' || date instanceof String)
        date = new Date(date);

    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }

    var time = "";

    if (flag_time) {
        var hh = date.getHours();
        var mi = date.getMinutes();

        if (hh < 10) {
            hh = '0' + hh
        }
        if (mi < 10) {
            mi = '0' + mi
        }

        time = "&nbsp;&nbsp;" + hh + ":" + mi;
    }

    return dd + '.' + mm + '.' + yyyy + time;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysTime(date, flag_sec)
{
    if (!date)
        return "";

    if (typeof date === 'string' || date instanceof String)
        date = new Date(date);

    var time = "";

    var hh = date.getHours();
    var mi = date.getMinutes();

    if (hh < 10) {
        hh = '0' + hh
    }
    if (mi < 10) {
        mi = '0' + mi
    }

    time = hh + ":" + mi;

    if (flag_sec)
    {
        var se = date.getSeconds();
        if (se < 10) {
            se = '0' + se
        }

        time += ":" + se;
    }

    return time;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysPhoneMask(phone)
{
    if (!phone)
        return "";

    var p = "";

    if (phone.length === 11)
    {
        p = phone.substring(0, 1)
            + "("
            + phone.substring(1, 4)
            + ")"
            + phone.substring(4, 7)
            + "-"
            + phone.substring(7)            ;
    }
    else
        p = phone;

    return p;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysStorageCodeAsk(uniq_code, code)
{
    let flag_ask = false;

    if (typeof Storage !== "undefined")
    {
        // Не нужно спрашивать если такой номер уже есть в списке
        flag_ask = true;

        const codesData = localStorage.getItem('codes');

        // Нашли в памяти какие-то коды, ищем есть ли в них наш
        // Не нужно добавлять только код там уже есть, иначе нужно предложить добавить
        if (codesData)
        {
            const codes = JSON.parse(codesData);
            if (codes && codes.length > 0)
            {
                for (let i = 0; i < codes.length; i++)
                {
                    if (codes[i].code === code)
                    {
                        // Нашли кодик в списке, значит в любом случае предлагать добавить его не нужно
                        flag_ask = false;
                        break;
                    }
                }
            }
        }
    }

    return flag_ask;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysStorageCode(uniq_code, code, flag_save)
{
    if (typeof Storage !== "undefined")
    {
        // Это коды которые нужно будет сохранить по новой
        let codesStorage = [];
        const codesData = localStorage.getItem('codes');

        if (!flag_save)
            flag_save = 0;
        else
            flag_save = 1;

        // Нашли в памяти какие-то коды, сохраним всё, кроме нашего
        if (codesData)
        {
            const codes = JSON.parse(codesData);

            if (codes && codes.length > 0)
            {
                for (let i = 0; i < codes.length; i++)
                {
                    if (codes[i].code !== code)
                    {
                        codesStorage.push(codes[i]);
                    }
                }
            }
        }

        // Тут в codesStorage либо пусто, либо есть все коды кроме нашего, добавим наш
        codesStorage.push({ code: code, uniq_code: uniq_code, flag_save: flag_save, date:Date.now() });

        localStorage.setItem("codes", JSON.stringify(codesStorage));
    }
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysStorageGetCodes()
{
    let codes = [];

    if (typeof Storage !== "undefined")
    {
        let codesStorage = [];
        const codesData = localStorage.getItem('codes');

        // Нашли в памяти какие-то коды
        if (codesData)
        {
            codesStorage = JSON.parse(codesData);

            if (codesStorage && codesStorage.length > 0)
            {
                for (let i = 0; i < codesStorage.length; i++)
                {
                    if (codesStorage[i].flag_save)
                    {
                        codes.push(codesStorage[i].uniq_code);
                    }
                }
            }
        }
    }

    return codes;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysStorageCodesClear()
{
    if (typeof Storage !== "undefined")
    {
        localStorage.removeItem('codes');
    }
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysStorageGetUniq(code)
{
    let uniq_code = "";
    code = code.trim();

    if (typeof Storage !== "undefined")
    {
        const codesData = localStorage.getItem('codes');

        // Нашли в памяти какие-то коды, ищем наш
        if (codesData)
        {
            const codes = JSON.parse(codesData);

            if (codes && codes.length > 0)
            {
                for (let i = 0; i < codes.length; i++)
                {
                    if (codes[i].code === code)
                    {
                        uniq_code = codes[i].uniq_code;
                        break;
                    }
                }
            }
        }
    }

    return uniq_code;
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysDeliveryDateRequest(delivery_request, dates_offer)
{
    const data = delivery_request.split(":");
    let item = null;

    // 20200820:09:14
    if (dates_offer && dates_offer.length > 0 && data && data.length === 3 && data[0].length === 8)
    {
        let year = parseInt(data[0].substring(0, 4));
        let month = parseInt(data[0].substring(4, 6)) - 1;
        let day = parseInt(data[0].substring(6));
        let date = null;

        if (!Number.isNaN(year) && !Number.isNaN(month) && !Number.isNaN(day))
        {
            date = new Date(year, month, day);
        }

        const time_begin = parseInt(data[1]);
        const time_end = parseInt(data[2]);

        if (date && !Number.isNaN(time_begin) && !Number.isNaN(time_end))
        {
            for (let i = 0; i < dates_offer.length; i++) {
                let d = new Date(dates_offer[i].date);
                let b = parseInt(dates_offer[i].time_begin);
                let e = parseInt(dates_offer[i].time_end);

                if (d.getTime() === date.getTime() && b === time_begin && e === time_end) {
                    item = dates_offer[i];
                    break;
                }
            }
        }

        return item;
    }
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export function sysLog(mode, data, origin, referrer)
{
    if (navigator.sendBeacon)
    {
        let d = new FormData();
        d.append("mode", mode);
        d.append("data", JSON.stringify(data));
        d.append("origin", origin ? origin : window.location.hostname);
        d.append("referrer", referrer ? referrer : document.referrer);
        
        navigator.sendBeacon("https://apiz.b2cpl.ru/api/log/beacon", d);
    }
}

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------