import React from 'react';
import { useHistory } from 'react-router-dom';
import { sysLog, sysStorageGetUniq } from '../js/dut_sys';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axiosDut from "../js/api_axios"
import { Icon } from '@material-ui/core';
import { Fragment } from 'react';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function DutSearchField()
{
    const [stateAlert, setAlertState] = React.useState(false);
    const [stateAlertText, setAlertText] = React.useState("Что-то пошло не так");
    const [stateInput, setInputState] = React.useState("");
    const history = useHistory();

    // ----------------------------------------------------------------

    const handleInputChange = (e) => {
        setInputState(e.target.value);
    };

    // ----------------------------------------------------------------

    const handleSearchClick = (e) =>
    {
        if (e)
            e.preventDefault();

        const code = stateInput;

        if (code.trim().length < 2)
        {
            sysLog("SearchField.Empty", code);
            setAlertText(`Введите трек номер`);
            setAlertState(true);
            return false;
        }

        const uniq_code = sysStorageGetUniq(code);

        if (uniq_code) {
            // Нашли код среди сохраненных
            sysLog("SearchField.Uniq", code);
            history.push(`/t/${uniq_code}`);
        }
        else
        {
            axiosDut.get(`packages/brief/${code}`)
                .then(res => {
                    if (res.data.flag_error) {
                        // Не нашли или ошибка
                        sysLog("SearchField.404", code);
                        setAlertText(res.data.message ? res.data.message : "Что-то не получилось");
                        setAlertState(true);
                    }
                    else {
                        // Нашли
                        sysLog("SearchField.Brief", code);
                        history.push(`/b/${res.data.payload.code}`);
                    }
                })
                .catch(error => {
                    sysLog("SearchField.Error", code);
                    console.log("error axios");
                    setAlertText(`Что-то пошло не так`);
                    setAlertState(true);
                });
        }

        return false;
    };

    // ----------------------------------------------------------------

    const handleAlertClose = (e, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setAlertState(false);

        return;
    };

    // ----------------------------------------------------------------

    const handleInputFocus = (event) => event.target.select();

    // ----------------------------------------------------------------

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    // ----------------------------------------------------------------

    return (
        <Fragment>

            <input type="text" onChange={handleInputChange} onFocus={handleInputFocus} onKeyPress={handleInputKeyPress} placeholder="Введите трек номер заказа" />
            <a href="/" onClick={handleSearchClick} className="transition">
                {/*<img src="/img/search.png" alt="" />*/}
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.7415 15.4843L12.5507 11.2762C13.6283 10.0396 14.2186 8.48359 14.2186 6.86374C14.2186 3.07914 11.0293 0 7.10932 0C3.1893 0 0 3.07914 0 6.86374C0 10.6484 3.1893 13.7275 7.10932 13.7275C8.58095 13.7275 9.98334 13.299 11.1823 12.4854L15.405 16.7255C15.5815 16.9024 15.8189 17 16.0732 17C16.314 17 16.5425 16.9114 16.7159 16.7502C17.0843 16.4079 17.0961 15.8403 16.7415 15.4843ZM7.10932 1.79054C10.0068 1.79054 12.364 4.06632 12.364 6.86374C12.364 9.66117 10.0068 11.9369 7.10932 11.9369C4.21181 11.9369 1.85461 9.66117 1.85461 6.86374C1.85461 4.06632 4.21181 1.79054 7.10932 1.79054Z" fill="white"/>
                </svg>

            </a>

            <Snackbar open={stateAlert} autoHideDuration={30000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {stateAlertText}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}

