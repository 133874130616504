import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import TopBar from './TopBar'
import TopBarIn from './TopBarIn';
import TopBarUniq from './TopBarUniq';

export class AppHeader extends Component {
    static displayName = AppHeader.name;

  render () {
      return (
          <Switch>
              <Route exact path='/' render={(props) => (<TopBar {...props} />)} />
              <Route path='/t/:uniq' render={(props) => (<TopBarUniq {...props} title="Управление доставкой" />)} />
              <Route path='/b/:uniq' render={(props) => (<TopBarUniq {...props} title="Трекинг заказа" />)} />
              <Route render={(props) => (<TopBarIn {...props} title="Страница не найдена" />)} />
          </Switch>
      );
  }
}
