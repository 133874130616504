import React from "react";
import "./Banner.css"
import iconAlert from "./icon-alert.svg"

const Banner = props => (
    <div className="banner-post">
        <div className="banner-post__top">
            <span className="banner-post__icon">
                <img src={iconAlert} />
            </span>
            <span className="banner-post__header">
                Обратите внимание!
            </span>
        </div>
        <div className="banner-post__bottom">
            Отслеживаемый трек-код {props.postCode} передан в службу доставки “Почта России”. Выбрать дату и время доставки невозможно! Отслеживать посылку можно на официальном трекинге “Почты России” www.pochta.ru/tracking
        </div>
    </div>
)

export default Banner