import React, { useEffect } from 'react';
import { sysLog } from '../js/dut_sys';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function Body404(props) {

    useEffect(() => {
        sysLog("Body404", document.location);
    }, []);

    return (
        <div className="content page-404">
            <div id="block-body">
                <h4>
                    404. Страница не найдена
                </h4>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------


