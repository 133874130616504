import React, { useState, useEffect } from 'react';
import { RenderDateTimeBlock, RenderStatusBlock, RenderTrackBlock } from "../js/Renders"
import axiosDut from "../js/api_axios"
import { sysLog, sysDeliveryDateRequest, sysStorageCode, sysStorageCodeAsk, sysDeliveryDate, sysDate, sysTime, sysPhoneMask, sysDateDay, sysDeliveryTime } from '../js/dut_sys';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DirectionsIcon from '@material-ui/icons/Directions';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'react-dadata/dist/react-dadata.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

const bgStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function DeliveryDateDialog2(props)
{
    const { onClose, open } = props;

    const handleClose = (e, result) =>
    {
        onClose(e, result, props.item);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className="dialog delivery-dialog"
        >
            <DialogTitle disableTypography className="dialog-title">
                <Typography variant="h6"></Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className="dialog-info">

                <div className="dialog-info-title">
                    <h3>Подтвердите выбранную дату и время</h3>
                    <div>Курьер свяжется с вами за час до доставки</div>
                </div>

                <div className="dialog-info-text">
                    <div className="delivery-confirm">
                        <div className="row address">
                            <div className="ico">
                                {/*<Icon>location_on</Icon>*/}
                                <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.5 0C2.9159 0 0 2.8388 0 6.32812C0 7.50705 0.335436 8.65758 0.97027 9.65574L6.13001 17.7507C6.22884 17.9058 6.40297 18 6.59024 18C6.59169 18 6.5931 18 6.59454 18C6.78344 17.9986 6.95793 17.9014 7.05496 17.7436L12.0832 9.57023C12.683 8.59317 13 7.47207 13 6.32812C13 2.8388 10.0841 0 6.5 0ZM11.1535 9.02876L6.58205 16.4597L1.89103 9.10005C1.36262 8.26924 1.07611 7.31074 1.07611 6.32812C1.07611 3.42035 3.51325 1.04766 6.5 1.04766C9.48675 1.04766 11.9203 3.42035 11.9203 6.32812C11.9203 7.2816 11.6527 8.21559 11.1535 9.02876Z" fill="#ADBED0"/>
                                    <path d="M6.5 3.16406C4.70795 3.16406 3.25 4.58346 3.25 6.32812C3.25 8.06164 4.68423 9.49219 6.5 9.49219C8.33816 9.49219 9.75 8.04259 9.75 6.32812C9.75 4.58346 8.29205 3.16406 6.5 3.16406ZM6.5 8.44453C5.29905 8.44453 4.32611 7.49415 4.32611 6.32812C4.32611 5.16502 5.3053 4.21172 6.5 4.21172C7.6947 4.21172 8.67028 5.16502 8.67028 6.32812C8.67028 7.47714 7.71998 8.44453 6.5 8.44453Z" fill="#ADBED0"/>
                                </svg>
                                <p>Адрес доставки:</p>
                            </div>
                            <div className="text">
                                {props.city}, {props.address}
                            </div>
                        </div>
                        <div className="delivery-date">
                            <div className="row date">
                                <div className="ico"><Icon>today</Icon></div>
                                <div className="text">
                                    {sysDeliveryDate(props.item.date)}
                                </div>
                            </div>
                            <div className="row time">
                                <div className="ico"><Icon>schedule</Icon></div>
                                <div className="text">
                                    {sysDeliveryTime(props.item.time_begin, props.item.time_end)}
                                </div>
                                </div>
                        </div>

                        {/*<div className="row person">*/}
                        {/*    <div className="ico"><Icon>person</Icon></div>*/}
                        {/*    <div className="text">*/}
                        {/*        {props.fio}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row phone">*/}
                        {/*    <div className="ico"><Icon>call</Icon></div>*/}
                        {/*    <div className="text">*/}
                        {/*        {sysPhoneMask(props.phone)}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => {handleClose(e, "ok")}} color="secondary">
                    Доставить {sysDeliveryDate(props.item.date)}
              </Button>
            </DialogActions>
            <a aria-label="close" onClick={handleClose} className="cancel">Отменить</a>
        </Dialog>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderDeliveryDayOfferMin2(props)
{
    if (props.date_min === props.day[0].date)
        return (
                <div className="date-nearest">Ближайшая дата</div>
            );

    return null;
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderDeliveryDayOffer2(props) {

    const handleOfferClick = (e, item) => {
        props.openDialog(item);
    };

    // ----------------------------------------------------------------

    return (
        props.day.map((item, idx) =>
            <div className="data-delivery time-radio-btn" key={idx}>
                <Link className="info popup-trigger" key={idx} onClick={(e) => {
                    handleOfferClick(e, item)
                }}>

                <div className="label">
                    <div className="data-delivery-row">
                        <p className="data-delivery-data">{sysDeliveryDate(item.date)}</p>
                        <RenderDeliveryDayOfferMin2 {...props} />
                    </div>
                    <div className="data-delivery-row-2">
                        <p className="data-delivery-time">{sysDeliveryTime(item.time_begin, item.time_end)}</p>
                        <p className="data-delivery-day">{sysDateDay(item.date, 0)}</p>
                    </div>
                </div>

                </Link>
            </div>
        ));
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderDeliveryDays2(props) {
    let date_min = "";

    if (props.days.length > 1)
        date_min = props.days[0][0].date;

    return (
        props.days.map((day, idx) =>
            <RenderDeliveryDayOffer2 key={idx} openDialog={props.openDialog} day={day} date_min={date_min} />
        ));
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderOtherDatesOfferWrap(props)
{
    if (!props.date_first || !props.time_first || !props.visible)
        return null

    return (RenderOtherDatesOffer(props))
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderOtherDatesOffer(props)
{
    const [stateSelectDate, setSelectDateState] = React.useState(props.date_first);
    const [stateSelectTime, setSelectTimeState] = React.useState(props.time_first);

    // ----------------------------------------------------------------

    const handleOtherButtonClick = () =>
    {
        if (!stateSelectDate || !stateSelectTime)
        {
            props.showAlert("Выберите дату и время");
            return;
        }

        let itemData = {};

        itemData.date = stateSelectDate;
        itemData.time_begin = stateSelectTime.time_begin;
        itemData.time_end = stateSelectTime.time_end;

        props.openDialog(itemData);
    };

    // ----------------------------------------------------------------

    const handleSelectDate = (event) => {
        setSelectDateState(event.target.value);
    };

    // ----------------------------------------------------------------

    const handleSelectTime = (event) => {
        setSelectTimeState(event.target.value);
    };

    // ----------------------------------------------------------------
    return (
        <div className="data-delivery-other">

            <div className="select-controls">
                <FormControl className="select-other select-date">
                    <img src="/img/calendar-red-2.svg" alt="" />
                    <Select
                        id="select-date"
                        value={stateSelectDate}
                        onChange={handleSelectDate}
                    >

                        {props.dates.map((item, idx) =>
                            <MenuItem key={idx} value={item.date}>
                                {item.date_text}
                            </MenuItem>
                        )}

                    </Select>
                </FormControl>

                <FormControl className="select-other select-time">
                    <img src="/img/time-red.svg" alt="" />
                    <Select
                        id="select-time"
                        value={stateSelectTime}
                        onChange={handleSelectTime}
                    >
                        {props.times.map((item, idx) =>
                            <MenuItem key={idx} value={item}>
                                {item.time_text}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>

            <div className="select-button">
                <Button color="primary" onClick={handleOtherButtonClick}>
                        Назначить
                </Button>
            </div>

        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderDeliveryBlock2(props)
{
    const [stateDlg, setDlgState] = React.useState(props.data.date_request ? true : false);
    const [stateDlgData] = React.useState({ fio: props.data.fio, address: props.data.address, city: props.data.city, phone: props.data.phone });
    const [stateDlgItem, setDlgItem] = React.useState(props.data.date_request ? props.data.date_request : {});
    const [stateAlert, setAlertState] = React.useState(false);
    const [stateSelect, setSelectState] = React.useState(false);
    const [stateAlertSeverity, setAlertSeverity] = React.useState("error");
    const [stateAlertText, setAlertText] = React.useState("Что-то пошло не так");
    const [stateAlertTitle, setAlertTitle] = React.useState("Ошибка");
    const [stateDatesText, setDatesText] = React.useState([]);
    const [stateTimesText, setTimesText] = React.useState([]);
    const [alertContent, setAlertContent] = React.useState([]);

    if (!props.data.flag_delivery  && !props.flag_delivery) {
        return null;
    }

    if (props.data.date_request) {
        sysLog("BodyUniq.DateDialog.Open.Auto", { uniq_code: props.data.uniq_code, item: props.data.date_request });
    }

    const datesOffer = props.data.dates_offer.length ? props.data.dates_offer : props.dates_offer;

    const offerDays = (offers, date_request) => {
        let days = [];

        if (offers && offers.length > 0)
        {
            let currentDate = null;
            let arrDate = [];

            for (let d = 0; d < offers.length; d++)
            {
                if (offers[d].date !== currentDate)
                {
                    if (arrDate.length > 0)
                        days.push(arrDate);

                    arrDate = [];
                    currentDate = offers[d].date;
                }

                if (date_request
                    && new Date(date_request.date).getTime() === new Date(offers[d].date).getTime()
                    && parseInt(date_request.time_begin) === parseInt(offers[d].time_begin)
                    && parseInt(date_request.time_end) === parseInt(offers[d].time_end)
                )
                {
                    offers[d].flag_request = 1;
                }
                else
                {
                    offers[d].flag_request = 0;
                }

                arrDate.push(offers[d]);
            }

            if (arrDate.length > 0)
                days.push(arrDate);
        }

        return days;
    };

    const days = offerDays(datesOffer, props.data.date_request);

    // ----------------------------------------------------------------

    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertState(false);

        return;
    };

    // ----------------------------------------------------------------

    const showAlert = (text, severity, title) => {

        setAlertSeverity(severity ? severity : "error");
        setAlertTitle(title);
        setAlertText(text);
        setAlertState(true);
    };

    // ----------------------------------------------------------------

    const handleDlgClose = (e, result, item) => {
        setDlgState(false);

        if (result !== "ok") {
            sysLog("BodyUniq.DateDialog.Close", { uniq_code: props.data.uniq_code, item: item });
            setTimeout(function () {
                showAlert("Вы не назначили дату и время" ,"", "Уупс!");
            }, 300);
        }
        else {
            // Нажата кнопка назначения доставки
            const payload = { uniq_code: props.data.uniq_code, date: item.date, time_begin: item.time_begin, time_end: item.time_end };

            axiosDut.post("packages/delivery/add", payload)
                .then(res => {
                    if (res.data.flag_error) {
                        sysLog("BodyUniq.DateDialog.Wrong", { uniq_code: props.data.uniq_code, item: item });

                        showAlert(res.data.message ? res.data.message : "Что-то пошло не так", "", "Уупс!");
                        props.refreshPage("Что-то пошло не так", "error", "Уупс!");
                    }
                    else {
                        sysLog("BodyUniq.DateDialog.Delivery", { uniq_code: props.data.uniq_code, item: item });
                        props.refreshPage("Изменить дату и время вы можете вот тут", "success", "Доставка назначена");
                    }
                })
                .catch(error => {
                    sysLog("BodyUniq.DateDialog.Error", { uniq_code: props.data.uniq_code, item: item });

                    console.log("error axios");
                    showAlert("Что-то пошло не так :(", "", "Уупс!");
                });
        }

    };

    // ----------------------------------------------------------------

    const openDialog = (itemData) => {
        sysLog("BodyUniq.DateDialog.Open", { uniq_code: props.data.uniq_code, item: itemData });

        setDlgItem(itemData);
        setDlgState(true);
    };

    // ----------------------------------------------------------------


    const handleSelectButtonClick = (e) =>
    {
        // Готовим даты и время для контролов
        let dates = [];
        let times = [];

        for (let i = 0; i < datesOffer.length; i++)
        {
            let flag_date = false;
            let flag_time = false;

            for (let d = 0; d < dates.length; d++)
            {
                if (dates[d].date === datesOffer[i].date)
                {
                    flag_date = true;
                    break
                }
            }

            for (let t = 0; t < times.length; t++) {
                if (times[t].time_begin === datesOffer[i].time_begin && times[t].time_end === datesOffer[i].time_end)
                {
                    flag_time = true;
                    break
                }
            }

            if (!flag_date)
            {
                dates.push({ date: datesOffer[i].date, date_text: sysDeliveryDate(datesOffer[i].date)});
            }

            if (!flag_time)
            {
                times.push({ time_begin: datesOffer[i].time_begin, time_end: datesOffer[i].time_end, time_text: sysDeliveryTime(datesOffer[i].time_begin, datesOffer[i].time_end)});
            }
        }

        setDatesText(dates);
        setTimesText(times);
        setSelectState(true);

        sysLog("BodyUniq.ButtonDates", props.data.uniq_code);
    };

    // ----------------------------------------------------------------

    return (
        <div className="block-delivery-offer2">

            <div className="block-data">
                <span>
                    <img src="/img/calendar-red.svg" alt="" />
                        <p>Выберите дату и время доставки</p>
                </span>
            </div>

            <div className="data-delivery-block select-time">
                <RenderDeliveryDays2 openDialog={openDialog} days={days} />
            </div>

            <div className={"data-delivery-select" + (stateSelect ? " hide" : "")}>
                <Button startIcon={<KeyboardArrowDownIcon />} variant="outlined" color="primary" onClick={handleSelectButtonClick}>
                    Выбрать другую дату
                </Button>
            </div>

            <RenderOtherDatesOfferWrap
                date_first={(stateDatesText && stateDatesText.length) ? stateDatesText[0].date : ""}
                time_first={(stateTimesText && stateTimesText.length) ? stateTimesText[0] : {}}
                showAlert={showAlert}
                openDialog={openDialog}
                visible={stateSelect}
                dates={stateDatesText}
                times={stateTimesText}
            />

            <Snackbar open={stateAlert} autoHideDuration={30000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={stateAlertSeverity}>
                    {stateAlertTitle ? <AlertTitle>{stateAlertTitle}</AlertTitle> : ''}
                    {stateAlertText}
                </Alert>
            </Snackbar>

            <DeliveryDateDialog2
                open={stateDlg}
                onClose={handleDlgClose}
                city={stateDlgData.city}
                address={stateDlgData.address}
                fio={stateDlgData.fio}
                phone={stateDlgData.phone}
                item={stateDlgItem}
            />

        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderInfoBlock2(props) {
    return (
        <div className="status status-new">

            <div className="status-new-info">
                <div className="status-head">
                    <svg width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.0406 8.25337L31.678 6.74078L26.5199 0.391109C26.3193 0.144187 26.013 0 25.6888 0H4.04325C1.81381 0 0 1.75903 0 3.92121V17.9475C0 19.5611 1.12996 20.9228 2.66249 21.3297C3.08363 23.4185 4.9844 25 7.25976 25C9.48955 25 11.3592 23.481 11.8295 21.4543H22.2635C22.7339 23.481 24.6035 25 26.8333 25C29.0631 25 30.9328 23.481 31.403 21.4543H32.4951C34.489 21.4543 36.1111 19.8812 36.1111 17.9474V11.7024C36.1112 10.2747 35.319 8.95714 34.0406 8.25337ZM29.0615 6.83559H20.5892V2.05199H25.1755L29.0615 6.83559ZM7.25976 22.9481C5.84317 22.9481 4.69064 21.8304 4.69064 20.4565C4.69064 19.0827 5.8431 17.965 7.25976 17.965C8.67642 17.965 9.82887 19.0827 9.82887 20.4565C9.82887 21.8304 8.67642 22.9481 7.25976 22.9481ZM7.25976 15.913C5.14464 15.913 3.35319 17.2797 2.77309 19.1501C2.37678 18.888 2.11589 18.4468 2.11589 17.9475V13.9818H3.96243C4.54669 13.9818 5.02037 13.5224 5.02037 12.9558C5.02037 12.3892 4.54669 11.9298 3.96243 11.9298H2.11589V3.92121C2.11589 2.89057 2.98051 2.05206 4.04325 2.05206H18.4734V19.4024H11.8162C11.3245 17.4039 9.46889 15.913 7.25976 15.913ZM26.8334 22.9481C25.4168 22.9481 24.2642 21.8304 24.2642 20.4565C24.2642 19.0827 25.4167 17.965 26.8334 17.965C28.25 17.965 29.4025 19.0827 29.4025 20.4565C29.4025 21.8304 28.2499 22.9481 26.8334 22.9481ZM32.4952 19.4024H31.3897C30.8981 17.4039 29.0424 15.913 26.8334 15.913C24.6243 15.913 22.7687 17.4039 22.277 19.4024H20.5892V8.88758H31.1907L32.9067 9.98628C32.9297 10.001 32.9532 10.0148 32.9773 10.0277C33.6052 10.3647 33.9953 11.0065 33.9953 11.7024V11.9298H31.8824C31.2981 11.9298 30.8244 12.3892 30.8244 12.9558C30.8244 13.5224 31.2981 13.9818 31.8824 13.9818H33.9953V17.9475C33.9953 18.7498 33.3223 19.4024 32.4952 19.4024Z" fill="#ADBED0"/>
                    </svg>
                    <p>Способ доставки: <span>{props.data.delivery_label}</span></p>
                </div>

                <div className="status-row row-price">
                    <div className="price">
                        <p>Cумма к оплате</p>
                        <span>{new Intl.NumberFormat('ru-RU').format(props.data.price_full)}</span>
                        <span className="rub">руб</span>
                    </div>
                    <div className="total-price">
                        <div className="total-price-col">
                            <p>Сумма заказа</p>
                            <span>{new Intl.NumberFormat('ru-RU').format(props.data.price_order)} руб</span>
                        </div>
                        <p className="vl pc"></p>
                        <div className="total-price-col">
                            <p>Сумма доставки</p>
                            <span>{new Intl.NumberFormat('ru-RU').format(props.data.price_delivery)} руб</span>
                        </div>
                    </div>
                </div>

                <div className="status-adress status-adress-new">
                    <div className="status-adress-row">
                        <div className="status-adress-name">
                            <div className="img-wrap">
                                <img src="/img/status-user.svg" alt="" className="pc" />
                                <img src="/img/status-user-red.svg" alt="" className="mobile" />
                            </div>
                            <p>{props.data.fio}<span className="extra">, тел. {props.data.phone}</span></p>
                        </div>
                    </div>
                    <div className="status-adress-row">
                        <div className="status-adress-firm">
                            <div className="img-wrap">
                                <img src="/img/status-cart.svg" alt="" className="pc" />
                                <img src="/img/status-cart-red.svg" alt="" className="mobile" />
                            </div>
                            <p>{props.data.client_name}</p>
                        </div>
                    </div>
                    <RenderAddress2 {...props} />
                </div>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderAddress2(props) {
    const api_key_yamap = "ff6fb12f-bfda-4e72-92f6-8a9596781b27";
    const api_key_datata = "da8ce08a83e46ef00e9da4cd6547ad145433def9";

    const [timeFire, setTimeFire] = React.useState();
    const [timeTimer, setTimeTimer] = React.useState();

    const [originVisibleAddr, setOriginVisibleAddr] = React.useState(false);
    const [originVisibleFio, setOriginVisibleFio] = React.useState(false);
    const [originVisiblePhone, setOriginVisiblePhone] = React.useState(false);

    const [openDialogPvz, setOpenDialogPvz] = React.useState(false);
    const [openDialogEdit, setOpenDialogEdit] = React.useState(false);

    const [cityAutoFocus, setCityAutoFocus] = React.useState(true);
    const [cityOpen, setCityOpen] = React.useState(false);
    const [cityValue, setCityValue] = React.useState(props.data.city);
    const [citySugg, setCitySugg] = React.useState([]);
    const [cityData, setCityData] = React.useState();

    const [addrAutoFocus, setAddrAutoFocus] = React.useState(false);
    const [addrOpen, setAddrOpen] = React.useState(false);
    const [addrValue, setAddrValue] = React.useState(props.data.address);
    const [addrSugg, setAddrSugg] = React.useState([]);
    const [addrData, setAddrData] = React.useState();

    const [fioValue, setFioValue] = React.useState(props.data.fio);
    const [phoneValue, setPhoneValue] = React.useState(props.data.phone);

    const [stateAlert, setAlertState] = React.useState(false);
    const [stateAlertSeverity, setAlertSeverity] = React.useState("error");
    const [stateAlertText, setAlertText] = React.useState("Что-то пошло не так");
    const [stateAlertTitle, setAlertTitle] = React.useState("Что-то пошло не так");

    const [valueTabs, setValueTabs] = React.useState(0);

    // ---------------------------------------------------------------------------

    const showAlert = (text, severity, title) => {

        setAlertSeverity(severity ? severity : "error");
        setAlertTitle(title);
        setAlertText(text);
        setAlertState(true);
    };

    // ---------------------------------------------------------------------------

    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertState(false);

        return;
    };

    // ---------------------------------------------------------------------------
    const handleClickOpenPVZ = () => {
        sysLog("BodyUniq.PVZDialog.Open", props.data.uniq_code);

        setOpenDialogPvz(true);

        window.handleYaMapReady = function () {
            const ymaps = window.ymaps;

            var pvzMap = new ymaps.Map("pvz-map", {
                center: [props.data.pvz.gps_lat, props.data.pvz.gps_lon],
                zoom: 13
            });

            pvzMap.geoObjects.add(
                new ymaps.Placemark([props.data.pvz.gps_lat, props.data.pvz.gps_lon],
                    {},
                    { preset: 'islands#redDotIcon' })
            );
        }

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://api-maps.yandex.ru/2.1/?apikey=" + api_key_yamap + "&load=package.standard&lang=ru_RU&onload=handleYaMapReady";
        document.head.appendChild(script);
    };

    // ---------------------------------------------------------------------------

    const handleClosePVZ = () => {
        setOpenDialogPvz(false);
    };

    // ----------------------------------------------------------------

    const handleClickEditData = () => {
        sysLog("BodyUniq.ButtonEdit", props.data.uniq_code);
        //showAlert("Изменение данных недоступно","","");

        setOpenDialogEdit(true);
    };

    // ---------------------------------------------------------------------------

    const handleTabChange = (event, newValue) => {
        setValueTabs(newValue);
    };

    // ---------------------------------------------------------------------------

    const handleCloseEdit = () => {
        // Тут закрываем диалог без сохранения. Может надо спросить про данные?

        setOpenDialogEdit(false);
    };

    // ---------------------------------------------------------------------------

    const handleSaveEdit = () => {
        // Тут закрываем диалог с сохранением

        //console.log("handleSaveEdit");

        let flag_error = false;

        if (!cityValue) {
            flag_error = true;
            showAlert("Укажите город доставки", "", "Ошибка");
        } else if (!addrValue) {
            flag_error = true;
            showAlert("Укажите адрес доставки", "", "Ошибка");
        }
        else if (!fioValue) {
            flag_error = true;
            showAlert("Укажите ФИО получателя", "", "Ошибка");
        }
        else if (!phoneValue) {
            flag_error = true;
            showAlert("Укажите контактный телефон", "", "Ошибка");
        }

        const payload = {};

        payload.uniq_code = props.data.uniq_code;
        payload.city = cityValue;
        payload.address = addrValue;
        payload.fio = fioValue;
        payload.phone = phoneValue;

        if (cityData && cityData.data && cityData.data.kladr_id) {
            payload.city_kladr = cityData.data.kladr_id;
        }

        if (addrData && addrData.data && addrData.data.kladr_id) {
            payload.addr_kladr = addrData.data.kladr_id;
        }

        //console.log(data);
        sysLog("BodyUniq.EditData" + (flag_error ? ".JS.Error" : ""), payload);

        if (flag_error === false) {
            // Отправляем измененные данные на сервер

            axiosDut.post("packages/edit/data", payload)
                .then(res => {
                    if (res.data.flag_error) {
                        sysLog("BodyUniq.EditData.Wrong", { uniq_code: props.data.uniq_code, data: payload, message: res.data.message });

                        showAlert(res.data.message ? res.data.message : "Что-то пошло не так", "", "Уупс!");
                        //props.refreshPage("Что-то пошло не так", "error");
                    }
                    else {
                        sysLog("BodyUniq.EditData.Done", { uniq_code: props.data.uniq_code, data: payload });
                        props.refreshPage("Ваши данные обновлены", "success", "");
                        setOpenDialogEdit(false);
                    }
                })
                .catch(error => {
                    sysLog("BodyUniq.EditData.Error", { uniq_code: props.data.uniq_code, data: payload });

                    console.log("error axios");
                    showAlert("Что-то пошло не так :(", "", "Уупс!");
                });
        }
    };

    // ---------------------------------------------------------------------------

    const handleDadataCity = (value, flag_deffer) => {

        // Обновим сохраненное значение City
        if (!flag_deffer)
            setCityValue(value);

        const timeNow = new Date();

        // Удаляем уже существующий таймер. В любом случае мы или создадим новый, или он не нужен
        if (timeTimer) {
            clearTimeout(timeTimer);
            setTimeTimer();
        }

        // Если пауза еще не прошла - создаем отложенный таймер и валим
        if (timeFire && (timeNow - timeFire < 150)) {
            setTimeTimer(
                setTimeout(() => { handleDadataCity("", true) }, 100)
            );
            return;
        }
        else
            setTimeFire(timeNow);

        axiosDut.post(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
            {
                "query": cityValue,
                "count": 9,
                "from_bound": { "value": "city" },
                "to_bound": { "value": "settlement" }
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Token ' + api_key_datata
                },
                //withCredentials: true // Может быть надо, а может быть нет
            }
        )
            .then(function (response) {
                // Всё ок
                setCitySugg(response.data.suggestions);

                setCityOpen(true);
                sysLog("BodyUniq.Dadata.CityRequest", value);
            })
            .catch(function (error) {
                // Всё не ок
                sysLog("BodyUniq.Dadata.CityRequest.Error", value);
                console.log("error axios");
            });
    }

    // ---------------------------------------------------------------------------

    const handleDadataAddr = (value, flag_deffer) => {
        //console.log("handleDadataAddr", value);
        //console.log("cityValue", cityValue);
        //console.log("cityData", cityData);

        if (!flag_deffer)
            setAddrValue(value);

        const timeNow = new Date();

        // Удаляем уже существующий таймер. В любом случае мы или создадим новый, или он не нужен
        if (timeTimer) {
            clearTimeout(timeTimer);
            setTimeTimer();
        }

        // Если пауза еще не прошла - создаем отложенный таймер и валим
        if (timeFire && (timeNow - timeFire < 150)) {
            setTimeTimer(
                setTimeout(() => { handleDadataAddr("", true) }, 100)
            );
            return;
        }
        else
            setTimeFire(timeNow);

        let loc = [{}];

        if (cityData && cityData.data) {
            if (cityData.data.kladr_id)
                loc[0]["kladr_id"] = cityData.data.kladr_id;
            if (cityData.data.region)
                loc[0]["region"] = cityData.data.region;
            if (cityData.data.area)
                loc[0]["area"] = cityData.data.area;
            if (cityData.data.city)
                loc[0]["city"] = cityData.data.city;
        }

        axiosDut.post(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
            {
                "query": addrValue,
                "count": 9,
                "from_bound": { "value": "street" },
                "to_bound": { "value": "house" },
                "locations": loc,
                "restrict_value": true
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Token ' + api_key_datata
                },
                //withCredentials: true // Может быть надо, а может быть нет
            }
        )
            .then(function (response) {
                // Всё ок
                //console.log(response.data.suggestions);

                setAddrSugg(response.data.suggestions);
                setAddrOpen(true);
                sysLog("BodyUniq.Dadata.AddrRequest", value);

            })
            .catch(function (error) {
                // Всё не ок
                sysLog("BodyUniq.Dadata.AddrRequest.Error", value);
                console.log("error axios");
            });
    }

    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------

    // Это ПВЗ
    if (props.data.flag_pvz && props.data.pvz) {
        const metro = props.data.pvz.pvz_metro ? (", метро \"" + props.data.pvz.pvz_metro + "\"") : "";

        return (
            <React.Fragment>
                <div className="status-adress-row wrap pvz">
                    <div className="status-adress-adress">
                        <div>
                            <p className="lbl">{props.data.address_label}</p>
                            <strong>{props.data.pvz.pvz_city}, {props.data.pvz.pvz_address}{metro}</strong>
                        </div>
                    </div>

                    <div className="status-adress-adress">
                        <div>
                            <p className="lbl">Время работы:</p>
                            <strong>{props.data.pvz.pvz_time}</strong>
                        </div>
                    </div>

                    <div className="status-adress-adress">
                        <div>
                            <p className="lbl">Телефон:</p>
                            <strong>{props.data.pvz.pvz_phone}</strong>
                        </div>
                    </div>

                    <div className="status-adress-adress btn">
                        <div>
                            <Button variant="outlined" startIcon={<DirectionsIcon />} size="small" color="primary" onClick={handleClickOpenPVZ}>Как добраться</Button>
                        </div>
                    </div>
                </div>


                <Dialog
                    open={openDialogPvz}
                    onClose={handleClosePVZ}
                    aria-labelledby="responsive-dialog-title"
                    className="dialog delivery-dialog pvz"
                >
                    <DialogTitle disableTypography className="dialog-title">
                        <Typography variant="h6">{props.data.address_label}</Typography>
                        <IconButton aria-label="close" onClick={handleClosePVZ}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent className="dialog-info">
                        <DialogContentText className="dialog-info-text">
                            <span className="pvz-comment">{props.data.pvz.pvz_address_comment}</span>
                        </DialogContentText>
                        <div className="pvz-map" id="pvz-map"></div>
                    </DialogContent>
                </Dialog>

            </React.Fragment>

        );
    }

    // Это не ПВЗ

    // ---------------------------------------------------------------------------

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`edit-tabpanel-${index}`}
                aria-labelledby={`edit-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="tab-box" p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    // ---------------------------------------------------------------------------

    function a11yProps(index) {
        return {
            id: `edit-tab-${index}`,
            'aria-controls': `edit-tabpanel-${index}`,
        };
    }

    // ---------------------------------------------------------------------------

    function AddressControlLine(props) {
        if (!props.data.flag_edit)
            return null;

        return (
            <div className="button-line">
                <p className="btn">
                    <Button startIcon={<EditIcon />} size="small" color="primary" onClick={handleClickEditData}>Исправить данные</Button>
                </p>
            </div>
        );
    }

    // ---------------------------------------------------------------------------

    return (
        <React.Fragment>

            <div className="status-adress-row">
                <div className="status-adress-adress">
                    <div className="img-wrap">
                        <img src="/img/status-marker.svg" alt="" className="pc" />
                        <img src="/img/status-marker-red.svg" alt="" className="mobile" />
                    </div>
                    <div className="address-line">
                        <strong><span className="prefix">{props.data.address_label}:</span>{props.data.city}, {props.data.address}</strong>
                    </div>

                    <AddressControlLine {...props} />
                </div>
            </div>

            <Dialog
                open={openDialogEdit}
                onClose={handleCloseEdit}
                aria-labelledby="responsive-dialog-title"
                className="delivery-dialog edit-data"
            >
                <DialogTitle disableTypography className="delivery-dialog-title">
                    <Typography variant="h6"></Typography>
                    <IconButton aria-label="close" onClick={handleCloseEdit}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <div className="delivery-dialog-info">
                    <Tabs
                        value={valueTabs}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        aria-label="Коррекция данных"
                    >
                        <Tab label="Адрес" {...a11yProps(0)} />
                        <Tab label="ФИО" {...a11yProps(1)} />
                        <Tab label="Телефон" {...a11yProps(2)} />
                    </Tabs>

                    <div className="delivery-dialog-info-text">
                        <TabPanel value={valueTabs} index={0}>

                            <div className="data-row">
                                <Autocomplete
                                    id="input-city"
                                    name="input-city"
                                    key="input-city"
                                    options={citySugg}
                                    getOptionLabel={option => typeof option === 'string' ? option : option.value}
                                    value={cityValue}
                                    freeSolo={true}
                                    open={cityOpen}
                                    onChange={(event, value, reason) => {
                                        //console.log("input-city onChange", value, reason);

                                        setCityOpen(false);
                                        setAddrOpen(false);
                                        setOriginVisibleAddr(true);

                                        // Value может быть объектом если это прилетело из выбора option, либо пусто
                                        // Строка вроде бы не попадается, но на всякий предусмотрим
                                        if (typeof value === "string")
                                            setCityValue(value);
                                        else if (value && value.value)
                                            setCityValue(value.value);
                                        else
                                            setCityValue("");

                                        if (reason === "select-option" && value) {
                                            setCityData(value);
                                            sysLog("BodyUniq.EditData.CitySelect", { uniq_code: props.data.uniq_code, value: value.value });

                                            setTimeout(function () {
                                                setCityAutoFocus(false);
                                                setAddrAutoFocus(true);
                                                setAddrValue("");
                                            }, 50);
                                        }
                                        else {
                                            setCityData()
                                        }
                                    }}
                                    onInputChange={(event, value, reason) => {
                                        //console.log("onInputChange", reason);

                                        if (reason === "input") {
                                            setAddrOpen(false);
                                            setAddrAutoFocus(false);
                                            setCityAutoFocus(true);
                                            setOriginVisibleAddr(true);

                                            // Поиск подсказок
                                            handleDadataCity(value);
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            autoFocus={cityAutoFocus}
                                            onBlur={(event) => {
                                                setCityValue(event.target.value);
                                                setCityOpen(false);
                                                setCityAutoFocus(false);
                                            }}
                                            onFocus={(event) => { }}
                                            label="Населенный пункт"
                                            variant="outlined" />}
                                />
                                <div className={"text-origin" + (originVisibleAddr ? (" on") : " off")}>Было: {props.data.city}</div>
                            </div>

                            <div className="data-row">
                                <Autocomplete
                                    id="input-addr"
                                    name="input-addr"
                                    key="input-addr"
                                    options={addrSugg}
                                    getOptionLabel={option => typeof option === 'string' ? option : option.value}
                                    value={addrValue}
                                    freeSolo={true}
                                    open={addrOpen}
                                    onChange={(event, value, reason) => {
                                        //console.log("input-addr onChange", value, reason);

                                        setCityOpen(false);
                                        setAddrOpen(false);
                                        setOriginVisibleAddr(true);

                                        // Value может быть объектом если это прилетело из выбора option, либо пусто
                                        // Строка вроде бы не попадается, но на всякий предусмотрим
                                        if (typeof value === "string")
                                            setAddrValue(value);
                                        else if (value && value.value)
                                            setAddrValue(value.value);
                                        else
                                            setAddrValue("");

                                        if (reason === "select-option" && value) {
                                            setAddrData(value);
                                            sysLog("BodyUniq.EditData.AddrSelect", { uniq_code: props.data.uniq_code, value: value.value });
                                        }
                                        else {
                                            setAddrData()
                                        }
                                    }}
                                    onInputChange={(event, value, reason) => {
                                        //console.log("onInputChange");

                                        if (reason === "input") {
                                            setCityOpen(false);
                                            setCityAutoFocus(false);
                                            setAddrAutoFocus(true);
                                            setOriginVisibleAddr(true);

                                            // Поиск подсказок
                                            handleDadataAddr(value);
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            autoFocus={addrAutoFocus}
                                            onBlur={(event) => {
                                                setAddrValue(event.target.value);
                                                setAddrOpen(false);
                                                setAddrAutoFocus(false);
                                            }}
                                            onFocus={(event) => { }}
                                            label="Адрес доставки"
                                            variant="outlined" />}
                                />

                                <div className={"text-origin" + (originVisibleAddr ? (" on") : " off")}>Было: {props.data.address}</div>
                            </div>
                        </TabPanel>

                        <TabPanel value={valueTabs} index={1}>
                            <div className="data-row fio">
                                <TextField
                                    id="input-fio"
                                    key="input-fio"
                                    name="input-fio"
                                    label="Получатель"
                                    variant="outlined"
                                    value={fioValue}
                                    onChange={e => {
                                        setFioValue(e.target.value);
                                        setOriginVisibleFio(true);
                                    }}
                                    autoFocus
                                />
                                <div className={"text-origin" + (originVisibleFio ? (" on") : " off")}>Было: {props.data.fio}</div>
                            </div>
                        </TabPanel>

                        <TabPanel value={valueTabs} index={2}>
                            <div className="data-row fio">
                                <TextField
                                    id="input-phone"
                                    key="input-phone"
                                    name="input-phone"
                                    label="Телефон"
                                    variant="outlined"
                                    value={phoneValue}
                                    onChange={e => {
                                        setPhoneValue(e.target.value);
                                        setOriginVisiblePhone(true);
                                    }}
                                    autoFocus
                                />
                                <div className={"text-origin" + (originVisiblePhone ? (" on") : " off")}>Было: {props.data.phone}</div>
                            </div>
                        </TabPanel>
                    </div>
                </div>

                <DialogActions>
                    <Button onClick={handleSaveEdit} color="primary" disabled={!originVisibleAddr && !originVisiblePhone && !originVisibleFio} >
                        Запомнить
                        </Button>
                </DialogActions>

                <Snackbar open={stateAlert} autoHideDuration={200000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity={stateAlertSeverity}>
                        {stateAlertTitle ? <AlertTitle>{stateAlertTitle}</AlertTitle> : ''}
                        {stateAlertText}
                    </Alert>
                </Snackbar>

            </Dialog>

        </React.Fragment>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function BodyUniq(props)
{
    const bgClasses = bgStyles();
    const [data, setData] = useState({});
    const [stamp, setStamp] = useState(Date.now());
    const [bgOpen, setBGOpen] = React.useState(true);
    const [stateAlert, setAlertState] = React.useState(false);
    const [stateAlertSeverity, setAlertSeverity] = React.useState("success");
    const [stateAlertText, setAlertText] = React.useState("Сообщение");
    const [stateAlertTitle, setAlertTitle] = React.useState("Заголовок");
    const [stateDatesOffer, setDatesOffer] = React.useState([]);
    const [stateFlagDelivery, setFlagDelivery] = React.useState(0);

    const showDelivery = (dates_offer) => {
        if (Array.isArray(dates_offer) && dates_offer.length) {
            setDatesOffer(dates_offer);
            setFlagDelivery(1);
            return;
        }
        setFlagDelivery(0);
    };

    const refreshPage = (message, severity, title) => {
        setStamp(Date.now());
        if(severity === "success") {
            setTimeout(() => {
                showDelivery([]);
            }, 750);
        }
        if (message)
        {
            setTimeout(function () { showAlert(message, severity, title)}, 500);
        }
    };

    // ----------------------------------------------------------------

    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertState(false);

        return;
    };

    // ----------------------------------------------------------------

    const showAlert = (text, severity, title) => {

        setAlertSeverity(severity ? severity : "success");
        setAlertTitle(title);
        setAlertText(text);
        setAlertState(true);
    };

    // ----------------------------------------------------------------

    useEffect(() =>
    {
        axiosDut.get(`packages/uniq/${props.match.params.uniq}`)
            .then(res => {
                if (props.match.params.delivery)
                {
                    const date_request = sysDeliveryDateRequest(props.match.params.delivery, res.data.payload.dates_offer);
                    if (date_request)
                    {
                        res.data.payload.date_request = date_request;
                    }
                    else
                    {
                        // Чтобы в истории событие проверки даты было после события поиска UNIQ
                        setTimeout(function () {
                            sysLog("BodyUniq.DateRequest.Wrong", { uniq_code: props.match.params.uniq, request: props.match.params.delivery });
                        }, 200);
                    }
                }

                setBGOpen(false);
                setData(res.data);

                setTimeout(function () {
                    const d = document.getElementById("content-uniq")
                    if (d && d.classList.contains("init-hide-delivery")) {
                        d.classList.remove("init-hide-delivery");
                    }
                }, 50);
            })
            .catch(error => {
                sysLog("BodyUniq.Error", props.match.params.uniq);
                console.log("error axios");
                setBGOpen(false);
                setData({ flag_error: true, message: "Ошибка при получении данных" });
            });

    }, [props.match.params.uniq, props.match.params.delivery, stamp]);

    // не нашли или какая-то ошибка
    if (data && data.flag_error)
    {
        sysLog("BodyUniq.404", props.match.params.uniq);

        return (
            <div className="content uniq error">
                <div id="block-body">
                    <Icon>error_outline</Icon>
                    <div className="error-title">Что-то пошло не так. {data.message}</div>
                </div>
            </div>
        );
    }

    // Нашли - все ок
    if (data && !data.flag_error && data.payload)
    {
        sysLog("BodyUniq.Found", { uniq_code: props.match.params.uniq,  });

        document.title = `${data.payload.code} - трекинг посылки`;

        // Если этот код можно сохранить, автоматом сохраняем
        if (sysStorageCodeAsk(data.payload.uniq_code, data.payload.code))
        {
            sysStorageCode(data.payload.uniq_code, data.payload.code, 1);
            setTimeout(function () {
                showAlert("Заказ добавлен в список отслеживания", "", "");

            }, 1000);
        }

        return (
            <React.Fragment>
                <div className="content-delivery content-delivery-new">

                    <div className="head">
                        <h2>{data.payload.state_name_tracking}</h2>
                    </div>

                    <RenderDateTimeBlock data={data.payload} selectDate={showDelivery} flag_delivery={stateFlagDelivery} showAlert={showAlert} flag_change={true} />
                    <RenderDeliveryBlock2 data={data.payload} dates_offer={stateDatesOffer} flag_delivery={stateFlagDelivery} refreshPage={refreshPage} />
                    <RenderTrackBlock data={data.payload} />
                    <RenderInfoBlock2 data={data.payload} />
                    <RenderStatusBlock history={data.payload.history} uniq_code={props.match.params.uniq} flag_parts={true} />
                </div>

                <Snackbar open={stateAlert} autoHideDuration={300000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity={stateAlertSeverity}>
                      {stateAlertTitle ? <AlertTitle>{stateAlertTitle}</AlertTitle> : ''}
                        {stateAlertText}
                    </Alert>
                </Snackbar>

            </React.Fragment>
        );
    }

    // Пока нет данных
    return (
        <div className="content uniq empty error">
            <div id="block-body">
                <div className="error-title">Получаем данные...</div>
                <Backdrop className={bgClasses.backdrop} open={bgOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------
