import React from "react";
import "../Slider.css";
import logoSbp from "../img/logo-sbp1.png"

const SlideItem = props => {
    return (
        <div className="slider-item" style={{background: "url("+props.image+") no-repeat"}}>
            <div className="slider-item-content">
                <div>
                    <img src={logoSbp} alt={props.header} />
                    <h2>{props.header}<br /></h2>
                    <p>{props.content}</p>
                </div>
                <div className="">
                    <p>
                        <a onClick={() => props.onSliderClick(props.id)} className="slider-control next">
                            {props.link}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SlideItem