import React, {Component} from "react";
import "./Slider.css"
import SlideItem from "./SliderItem/SliderItem";
import slide1 from "./img/slide-1.svg"
import slide2 from "./img/slide-2.svg"
import slide3 from "./img/slide-3.svg"
import slide4 from "./img/slide-4.svg"



class Slider extends Component {

    onSlideClickHandler = (slideId) => {
        console.log('answer Id:', slideId)
        this.setState({
            activeSlide:
                this.state.activeSlide === this.state.slide.length - 1 ?
                    0 : this.state.activeSlide + 1
        });
    }

    state = {
        activeSlide: 0,
        slide:[
            {
                header: 'Самый быстрый способ оплаты',
                content: 'Теперь вам доступна оплата заказа через «Систему быстрых платежей» без наличных и банковских карт!',
                image: slide1,
                link: 'Как это работает?',
                clickHandler: this.onSlideClickHandler,
                id: 0,
            },
            {
                header: 'Откройте мобильное приложение своего банка',
                content: 'В разделе «Платежи и переводы» выберите пункт оплата по QR-коду',
                image: slide2,
                link: 'Ок, давайте дальше!',
                clickHandler: this.onSlideClickHandler,
                id: 1,
            },
            {
                header: 'Отсканируйте QR-код, который предъявил вам курьер',
                content: 'Название платежа: Dostavka B2CPL',
                image: slide3,
                link: 'Как это работает?',
                clickHandler: this.onSlideClickHandler,
                id: 2,
            },
            {
                header: 'Вот и всё, товар оплачен!',
                content: 'После оплаты чек операции сохранится в вашем приложении',
                image: slide4,
                link: 'Супер, хочу попробовать!' ,
                clickHandler: this.props.closeSlider,
                id: 3,
            }
        ]
    }
    render() {
        return (
            <div className="slider-b2cpl">
                <div className="slider">
                    <div className="slider-inner">
                        <SlideItem
                            header={this.state.slide[this.state.activeSlide].header}
                            content={this.state.slide[this.state.activeSlide].content}
                            image={this.state.slide[this.state.activeSlide].image}
                            link={this.state.slide[this.state.activeSlide].link}
                            onSliderClick={this.state.slide[this.state.activeSlide].clickHandler}
                            id={this.state.slide[this.state.activeSlide].id}
                        />
                        <ol className="slider-indicators">
                            {this.state.slide.map((slide, index) => {
                                if (slide.id === this.state.activeSlide) {
                                    return (
                                        <li
                                            key={index}
                                        >
                                            <span className="slider-bullet active"></span>

                                        </li>
                                    )
                                } else {
                                    return (
                                        <li
                                            key={index}
                                            onClick={() => this.onSlideClickHandler(slide.id)}
                                        >
                                            <span className="slider-bullet">•</span>

                                        </li>
                                    )
                                }
                            })  }

                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider