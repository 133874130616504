import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import axiosDut from "../js/api_axios";
import { sysDate, sysDeliveryTime, sysTime, sysLog } from '../js/dut_sys';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slider from "../components/Slider/Slider"
import Banner from "../components/Banner/Banner";

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderChangeButton(props)
{
    if (props.flag_change)
    {
        return (
            <div className="change-button">
                <Button color="primary" onClick={props.handleChangeButtonClick}>
                    Изменить
                </Button>
            </div>
        );
    }

    return null;
}

export function RenderDateTimeBlock(props)
{
    let flag_arrive = false;
    let flag_delivery = false;
    let title = "";

    if (props.data.delivery_date && props.data.delivery_date !== "0001-01-01T00:00:00" && props.data.delivery_time_begin && props.data.delivery_time_end) {
        flag_delivery = true;
        title = "Выбраная вами дата и время доставки";
    }

    if (props.data.date_estimate_arrive && props.data.date_estimate_arrive !== "0001-01-01T00:00:00") {
        flag_arrive = true;
        title = "Дата прибытия в ваш город";
    }

    // ----------------------------------------------------------------

    const handleChangeButtonClick = () =>
    {
        if (props.flag_delivery) {
            props.selectDate([]);
            sysLog("BodyUniq.DateChange.ChangedMind", {
                uniq_code: props.data.uniq_code
            })
            return;
        }
        axiosDut.get(`packages/changeallow/${props.data.code}`)
            .then(res => {
                if (res.data.flag_error) {
                    sysLog(`BodyUniq.DateChange.${props.data.uniq_code}`, {
                        status_code: res.data.status_code,
                        code: props.data.code,
                        uniq_code: props.data.uniq_code
                    });
                    props.showAlert(res.data.message || "Что-то не получилось :с", "error");
                } else {
                    const payload = res.data.payload;
                    if (payload.flag_allow) {
                        props.selectDate(payload.dates_offer);
                        sysLog("BodyUniq.DateChange", {
                            uniq_code: props.data.uniq_code
                        });
                    } else {
                        props.showAlert(payload.message || "Сейчас нельзя поменять дату", "error");
                        sysLog("BodyUniq.DateChange.0", {
                            message: payload.message,
                            code: props.data.code,
                            uniq_code: props.data.uniq_code
                        });
                    }
                }
            });
    };

    // ----------------------------------------------------------------

    if (props.data.flag_delivery || (!flag_arrive && !flag_delivery)) {
        return null;
    }

    // ----------------------------------------------------------------
    // Есть дата доставки
    if (flag_delivery) {
        return (
            <div className="delivery-date-block">

                <div className="title">
                    {title}
                </div>
                <div className="delivery-data">

                    <div className="block date">
                        <img src="/img/calendar-3.svg" alt="" />
                        <div className="value">
                            {sysDate(props.data.delivery_date)}
                        </div>
                    </div>

                    <div className="block time">
                        <img src="/img/time-3.svg" alt="" />
                        <div className="value">
                            {sysDeliveryTime(props.data.delivery_time_begin, props.data.delivery_time_end)}
                        </div>
                    </div>
                </div>

                <RenderChangeButton handleChangeButtonClick={handleChangeButtonClick} flag_change={props.flag_change} />
            </div>
        );
    }

    // ----------------------------------------------------------------
    // Есть дата прибытия
    if (flag_arrive) {
        return (
            <div className="delivery-date-block arrive">
                <div className="title">
                    {title}
                </div>
                <div className="delivery-data">

                    <div className="block date">
                        <img src="/img/calendar-3.svg" alt="" />
                        <div className="value">
                            {sysDate(props.data.date_estimate_arrive)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderPackageParts(props) {
    return (
        props.parts.map((item, idx) =>
            <React.Fragment key={idx}>
                <div className="cell first">
                    <span className="order-span-title">
                        {item.part_name + (item.part_code ? (" (" + item.part_code + ")") : "")}
                    </span>
                </div>
                <div className="cell last">
                    <span className="order-span-quality">
                        {item.part_qty} шт
                    </span>
                </div>
            </React.Fragment>
        ));
}

function RenderPartsButton(props) {
    if (props.flag_parts) {
        return (
            <Button className="history-button" onClick={props.handlePackageParts}>
                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.9776 4.0576C23.9776 4.05129 23.9776 4.04552 23.9776 4.03922C23.9573 3.9789 23.9262 3.92279 23.8857 3.87383C23.8737 3.8586 23.8597 3.84757 23.8466 3.83392C23.8163 3.80334 23.7826 3.77656 23.7459 3.75411C23.7267 3.74283 23.7069 3.73265 23.6864 3.72366C23.6712 3.71493 23.6555 3.70706 23.6395 3.70003L12.1612 0.0246611C12.0584 -0.00822037 11.9479 -0.00822037 11.845 0.0246611L0.366783 3.70003C0.149739 3.76796 0.00130435 3.96952 0 4.19831V16.7996C0.000195652 17.0287 0.148044 17.2312 0.365217 17.3L11.8435 20.9753C11.9463 21.0082 12.0568 21.0082 12.1597 20.9753L23.6379 17.3C23.8539 17.2301 24.0002 17.0279 24 16.7996V4.19831C23.999 4.1506 23.9914 4.10328 23.9776 4.0576ZM12 1.07424L21.7565 4.19831L19.7384 4.84465L9.98244 1.72006L12 1.07424ZM8.26122 2.27136L18.0177 5.39543L16.0002 6.04177L6.24365 2.9177L8.26122 2.27136ZM22.9565 16.4153L12.5217 19.7567V17.8497C12.5217 17.5597 12.2881 17.3246 12 17.3246C11.7119 17.3246 11.4783 17.5597 11.4783 17.8497V19.7567L1.04348 16.4158V4.58212L4.52191 3.46849L14.2784 6.59308L12 7.32238L3.28696 4.53329C3.01233 4.44541 2.71898 4.5982 2.63165 4.87458C2.54433 5.15095 2.69615 5.44616 2.97078 5.53405L11.4783 8.2575V15.7495C11.4783 16.0394 11.7119 16.2745 12 16.2745C12.2881 16.2745 12.5217 16.0394 12.5217 15.7495V8.2575L15.4784 7.3124V10.7956C15.4784 11.0856 15.712 11.3207 16.0002 11.3207C16.0533 11.3203 16.106 11.312 16.1567 11.296L17.3739 10.9053C17.6485 10.8175 17.8004 10.5222 17.713 10.2459C17.6257 9.9695 17.3324 9.81671 17.0577 9.90459L16.5204 10.0768V6.97899L19.2157 6.1158V9.21362L18.623 9.40316C18.3483 9.49104 18.1965 9.78626 18.2838 10.0626C18.3525 10.2801 18.5528 10.4279 18.7795 10.4286C18.8326 10.4287 18.8855 10.4203 18.936 10.4039L19.8929 10.0978C20.11 10.0291 20.2579 9.82655 20.2581 9.59743V5.77977L22.9565 4.91658V16.4153Z" fill="#F13D52"/>
                    <path d="M2.6087 13.1242C2.32057 13.1242 2.08696 13.3593 2.08696 13.6493V15.2244C2.08696 15.5144 2.32057 15.7495 2.6087 15.7495C2.89683 15.7495 3.13044 15.5144 3.13044 15.2244V13.6493C3.13044 13.3593 2.89683 13.1242 2.6087 13.1242Z" fill="#F13D52"/>
                    <path d="M4.69565 13.6493C4.40752 13.6493 4.17391 13.8844 4.17391 14.1743V15.7495C4.17391 16.0394 4.40752 16.2745 4.69565 16.2745C4.98378 16.2745 5.21739 16.0394 5.21739 15.7495V14.1743C5.21739 13.8844 4.98378 13.6493 4.69565 13.6493Z" fill="#F13D52"/>
                </svg>

                Состав заказа
            </Button>
        );
    }

    return null;
}

export function RenderStatusBlock(props) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [packageParts, setPackageParts] = React.useState([]);

    if (!props.history) {
        return null;
    }

    // ----------------------------------------------------

    const historyItems = props.history.map
    (
        (item, idx) =>
            <div className="history-content-row" key={idx}>
                <div className="hystory-date-time">
                    <p className="hystory-date">{sysDate(item.flow_date)}</p>
                    <span className="hystory-time">{sysTime(item.flow_date)}</span>
                </div>
                <p className="hystory-name">{item.flow_name}</p>
            </div>
    );

    // ----------------------------------------------------

    const handlePackageParts = () => {
        axiosDut.get(`packages/parts/${props.uniq_code}`)
            .then(res => {
                setPackageParts(res.data.payload);
                setOpenDialog(true);
            })
            .catch(error => {
                console.log("error axios");
            });
    };

    // ----------------------------------------------------

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ----------------------------------------------------

    return (
        <div className="history history-new">
            <div className="history-head">
                <div className="history-title">
                    <h3>История доставки заказа</h3>
                </div>
            </div>

            {/*<RenderPartsButton handlePackageParts={handlePackageParts} flag_parts={props.flag_parts} />*/}

            <div className="history-content">
                {historyItems}
            </div>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                className="dialog delivery-dialog parts"
            >
                <DialogTitle disableTypography className="dialog-title">
                    <Typography variant="h6">Состав заказа</Typography>
                    <IconButton aria-label="close" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="dialog-info">
                    <div className="order-content">
                        <RenderPackageParts parts={packageParts} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------
let activeSlide
export function RenderTrackBlock(props) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogSlide, setOpenDialogSlide] = React.useState(false);
    const [packageParts, setPackageParts] = React.useState([]);

    const handlePackageParts = () => {
        axiosDut.get(`packages/parts/${props.data.uniq_code}`)
            .then(res => {
                setPackageParts(res.data.payload);
                setOpenDialog(true);
            })
            .catch(error => {
                console.log("error axios");
            });
    };

    useEffect(() => {
        handleOpenSlide()
        activeSlide = false
    });

    const handleOpenSlide = () => {
        let slideCookie = document.cookie.indexOf('slide=false')
        console.log(document.cookie)
        if (activeSlide !== false && slideCookie === -1) {
            setOpenDialogSlide(true);
        }
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleCloseDialogSlide = () => {
        var date= (new Date(Date.now()+ 86400*24000)).toUTCString();
        document.cookie = `slide=false; expires=` + date;
        setOpenDialogSlide(false);
    };


    let class_track = "trek-code trek-code-new";

    if (props.data.flag_delivery)
        class_track += " delivery";
    else
        class_track += " normal";

    // flag_stage_delivery = 2 Активно (белая иконка)
    // flag_stage_delivery = 1 Пройдено (серая иконка)
    // flag_stage_delivery = 0 В будущем (серая иконка)

    return (
        <React.Fragment>
            {props.data.post_code ? <Banner postCode={props.data.code}  /> : ""}
            <div className={class_track}>
            <Dialog
                open={openDialogSlide}
                onClose={() => handleCloseDialogSlide()}
                className="dialog2 slider-par delivery-dialog parts2"
            >
                <DialogTitle disableTypography className="dialog-title">
                    <IconButton aria-label="close" onClick={() => handleCloseDialogSlide()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="dialog-info">
                    <div className="order-content">
                        <Slider
                        closeSlider={handleCloseDialogSlide}
                        />
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog()}
                className="dialog delivery-dialog parts"
            >
                <DialogTitle disableTypography className="dialog-title">
                    <Typography variant="h6">Состав заказа</Typography>
                    <IconButton aria-label="close" onClick={() => handleCloseDialog()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="dialog-info">
                    <div className="order-content">
                        <RenderPackageParts parts={packageParts} />
                    </div>
                </DialogContent>
            </Dialog>
            <div className="trek-code-head">
                {/*<h3>{props.data.delivery_label}</h3>*/}
                <div>
                    <div>
                        <span>{props.data.post_code ? 'Трек-код Почты России' : ''} </span><span style={{ color:"#1850A6"}}>{props.data.post_code}</span>
                    </div>
                    <div>
                        <span>{props.data.post_code ? 'Номер заказа B2CPL' : 'Отслеживание трек кода'}</span> <span style={{ color:"#F13D52"}}>{props.data.code}</span>
                    </div>
                </div>
                <RenderPartsButton handlePackageParts={handlePackageParts} flag_parts={true} />
            </div>
            <hr className="trek-code-line-new" />

            <div className="trek-code-icon">

                <div className={props.data.flag_stage_prepare === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_prepare === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_prepare === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_prepare === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-1" + (props.data.flag_stage_prepare === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>Готовится к отгрузке</p>
                </div>

                <div className={props.data.flag_stage_ship === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_ship === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_ship === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_ship === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-2" + (props.data.flag_stage_ship === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>Заказ отправлен</p>
                </div>
                <div className={props.data.flag_stage_arrive === 2 ? "icon-active icon-default-center" : ("icon-default icon-default-center" + (props.data.flag_stage_arrive === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_arrive === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_arrive === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-3" + (props.data.flag_stage_arrive === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>Прибытие в распред. центр</p>
                </div>

                <div className={props.data.flag_stage_dispatch === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_dispatch === 1 ? " icon-grey" : ""))}>
                    {
                        props.data.post_code ?
                            <div className={props.data.flag_stage_dispatch === 2 ? "icon-active-blue icon-active-img" : ("icon-default-img" + (props.data.flag_stage_dispatch === 1 ? " passed" : ""))}>
                                <img src={"/img/icon-8" + (props.data.flag_stage_dispatch === 2 ? "-w" : "") + ".svg"} style={{padding: 12}} alt="" />
                            </div>
                            :
                            <div className={props.data.flag_stage_dispatch === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_dispatch === 1 ? " passed" : ""))}>
                                <img src={"/img/icon-4" + (props.data.flag_stage_dispatch === 2 ? "-w" : "") + ".png"} alt="" />
                            </div>
                    }
                    <p>{props.data.post_code ? props.data.delivery_label : props.data.dispatch_label}</p>
                </div>

                <div className={props.data.flag_stage_delivery === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_delivery === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_delivery === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_delivery === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-5" + (props.data.flag_stage_delivery === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>{props.data.final_label}</p>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------