import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import FooterFirst from './FooterFirst'
import FooterIn from './FooterIn';

export class AppFooter extends Component {
    static displayName = AppFooter.name;

  render () {
      return (
          <Switch>
              <Route exact path='/' render={(props) => (<FooterFirst {...props} />)} />
              <Route path='/t/:uniq' render={(props) => (<FooterIn {...props} />)} />
              <Route path='/b/:uniq' render={(props) => (<FooterIn {...props} />)} />
              <Route render={(props) => (<FooterIn {...props} />)} />
          </Switch>
      );
  }
}
