import React from 'react';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function DutAppBarIn(props) {

    return (
        <div className="header">
            <div className="header-content">
                <a className="back-link" href="/">
                    {/*<span className="material-icons">arrow_back</span>*/}
                    <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.292892 7.2929C-0.0976315 7.68342 -0.0976314 8.31658 0.292893 8.70711L6.65686 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928934C7.68054 0.538409 7.04738 0.53841 6.65685 0.928934L0.292892 7.2929ZM27 7L1 7L1 9L27 9L27 7Z" fill="#8798AA"/>
                    </svg>
                    <p>Вернуться назад</p>
                </a>
                <a href="/">
                    {/*<img src="/img/logo.png" alt=""/>*/}
                    <svg className="svg-images-type" width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.50184 0V14.1826C19.8282 2.85619 20.5176 0 36.473 0L15.6916 21.0768H29.4802L21.1086 29.744H0.228671C0.228671 14.9704 -2.13509 10.243 8.50184 0Z" fill="#FF0014"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M50.951 29.744V15.6599C39.6247 26.7893 38.7383 29.744 22.8813 29.744L43.7613 8.56861H29.8741L38.2458 0H59.1257C59.1257 14.872 61.4895 19.4025 50.951 29.744Z" fill="#D70C1E"/>
                    </svg>
                </a>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------


