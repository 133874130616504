import React, { Component } from 'react';
import { AppHeader } from './components/AppHeader';
import { AppBody } from './components/AppBody';
import { AppFooter } from './components/AppFooter';

import './css/site.scss'

export default class App extends Component {
  static displayName = App.name;

    render() {
      return (
          <div className="root-box">
            <AppHeader />
            <AppBody />
            <AppFooter />
          </div>
    );
  }
}
